.explore-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding-top: 20px;
  margin: 0 auto;
  border-top-right-radius: 45px;
  border-top-left-radius: 45px;
}

/* sections styles */
.section-search, .section-heading-img, .section-news, .section-staff-tips {
  padding: 0 25px;
  position: relative;
}

/* search section */
.section-search input[type=input] {
  width: 100%;
  padding: 15px 25px;
  border-radius: 7px;
  border: 1px solid #ccc;
  /* background: url('assets/search.svg'); */
  background-repeat: no-repeat;
  background-position: 90% center;
  background-size: 14px;
}
.section-search input[type=input]::placeholder {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 1.429;
  color: rgba(0, 0, 0, 0.5);
}
.section-search {
  padding: 10px 25px 30px;
}

/* banner img section */
.section-heading-img .img-wrap {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.section-heading-img .img-wrap img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

/* news section */
.section-news {
  padding-bottom: 20px;
}
.section-news .heading-wrap .icon-wrap {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.section-news .heading-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 20px;
}
.section-news .featured-wrap .img-wrap {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.section-news .featured-wrap .img-wrap img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
.section-news .content-wrapper {
  margin-top: 15px;
}
.section-news .content-wrapper .heading-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.section-news .content-wrapper .heading-wrapper .cat-tag {
  padding: 5px;
  background-color: #F2F2F2;
}
.section-news .content-wrapper .description-wrap {
  padding: 5px 0 0;
}
.news-article-grid {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.news-article-wrap {
  display: flex;
  flex-direction: row;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.news-article-wrap:last-child {
  margin-bottom: 0px;
}
.news-article-wrap .img-wrap {
  width: 150px;
  height: 100px;
  border-radius: 10px;
}
.news-article-wrap .img-wrap img {
  border-radius: 10px;
}
.news-article-wrap .content-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}
.news-article-wrap .content-wrap .title {
  margin-bottom: 13px;
}
.news-article-wrap::after {
  content: '';
  width: 100%;
  display: block;
  background-color: #D8D8D8;
  height: 1px;
  position: absolute;
  bottom: 0;
  opacity: 40%;
}

/* staff tips section */
.section-staff-tips {
  background-color: #F2F2F2;
  padding: 20px 25px 30px;
}
.section-staff-tips .heading-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.section-staff-tips .heading-wrap .icon-wrap {
  width: 14px;
  height: 15px;
  margin-right: 10px;
}
.staff-grid-wrapper {
  padding: 20px 0 0;
}
.staff-grid-wrapper .staff-grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 15px 10px;
}
.staff-grid-wrapper .staff-grid .staff-wrap .img-wrap {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
}
.staff-grid-wrapper .staff-grid .staff-wrap .img-wrap img {
  border-radius: 10px;
}

/* global styles */
.explore-container h3.screen-top-header {
  font-family: 'Nunito', sans-serif;
  font-weight: 900;
  color: #FF7800;
  font-size: 20px;
  margin: 0;
  text-align: center;
  line-height: 1.3;
  text-transform: uppercase;
}
.explore-container h4.subheading {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
}
.explore-container h4.subheading02 {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.375;
}
.explore-container h4.medium-title {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.429;
  color: #000;
}
.explore-container h4.small-title {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.2px;
}
.explore-container p.extra-small {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 8px;
  line-height: 1.25;
  margin: 0;
  text-transform: uppercase;
  color: #282828;
}
.explore-container p.medium {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.429;
  color: #000;
  margin: 0;
  letter-spacing: 0.2px;
}
.explore-container p.date {
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  line-height: 1.6;
  margin: 0;
  color: rgba(40, 40, 40, 0.5);
}
.explore-container button{
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: #FF7800;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 1;
  margin: 0;
  padding: 0;
}
.explore-container .button-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.album-container {
  position: relative;
  height: 210px;
  width: 100%;
  overflow: hidden;
}

.album-container:after {
  content: "";
  position: absolute;
  height: inherit;
  width: inherit;
  top: 0;
  left: 0;
  right: 0;
}

.album-container .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.album-container .image {
  position: relative;
  height: 200px;
  width: 100%;
}

.album-container .stripes {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  height: 50px;
  display: flex;
  align-items: center;
}


.album-container .stripes span {
  width: 5px;
  height: 5px;
  background-color: #000;
  content: '';
  display: block;
  border-radius: 10px;
  margin: 0 6px;
  cursor: pointer;
}