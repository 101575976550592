@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Roboto', sans-serif;
  }
}

@layer components {
  .font-primary {
    font-family: 'Nunito', sans-serif;
  }
}
