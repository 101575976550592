.app-nav-active > span,
.tab-active {
  text-decoration: underline;
}
#qr-code svg {
  width: 100%;
  height: 100%;
}

/* google maps */
.gm-style .gm-style-iw-c {
  top: 15px !important;
  left: 10px !important;
}
.gm-style .gm-style-iw-t::after {
  top: -27px !important;
  display: none;
}
.gm-style-iw-c {
  /* background: url('../assets/images/bg-info-modal.png') no-repeat center /
    contain; */
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  width: 200px !important;
  height: 200px !important;
}
.gm-style-iw-d {
  overflow: hidden !important;
}
.gm-style .gm-style-iw-tc {
  display: none;
}

/* scrollbar */
.scrollbar-thin.scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 15px;
}
.scrollbar-thin.scrollbar::-webkit-scrollbar-track:not(:hover) {
  border-radius: 100vh;
  background: transparent;
}
.scrollbar-thin.scrollbar::-webkit-scrollbar-thumb:not(:hover) {
  background: transparent;
  border-radius: 100vh;
}
.scrollbar-thin.scrollbar::-webkit-scrollbar-thumb:not(:hover) {
  background: transparent;
}
.scrollbar::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: rgba(221, 221, 221, 0.5);
}
.scrollbar::-webkit-scrollbar-thumb {
  background: #dddddd;
  border-radius: 100vh;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #b7b7b7;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* remove input number arrows */
input.hide-input-number-arrows::-webkit-outer-spin-button,
input.hide-input-number-arrows::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}
input.hide-input-number-arrows[type='number'] {
  appearance: none textfield;
  -moz-appearance: textfield;
}

/* react slick */
.vehicle-slider .slick-dots {
  bottom: 63px;
}
.vehicle-slider .slick-dots li,
.vehicle-slider .slick-dots li button,
.vehicle-slider .slick-dots li button:before {
  margin: 0;
  padding: 0;
  width: 10px;
  height: auto;
}
.vehicle-slider .slick-dots li.slick-active button:before {
  color: #ff7800;
}
.vehicle-slider .slick-next {
  right: 10px;
  z-index: 10;
}
.vehicle-slider .slick-prev {
  left: 10px;
  z-index: 10;
}
.vehicle-slider .slick-next:before {
  content: '❯';
  color: #ff7800;
}
.vehicle-slider .slick-prev:before {
  content: '❮';
  color: #ff7800;
}
.vehicle-slider .slick-slide.slick-active {
  z-index: 10;
}
.vehicle-slider .slick-dots li.slick-active button:before {
  background-color: #ff7800;
}
.vehicle-slider .slick-dots li button:before {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  content: '';
  display: block;
  background-color: #b7b7b7;
}

input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

.flag-dropdown{
  background: white !important;
  border:transparent !important;
  transform: translate(1px,-5px);
  z-index: 12 !important;
}

.flag-dropdown>.country-list{
  transform: translateY(-5px);
}
.selected-flag>.flag{
  border-radius: 100% !important;
  image-rendering: high-quality !important;
  transform:scale(1.5,2) translateX(7px);

}
.selected-flag>.flag>.arrow{
  transform: scale(0.8,.5) translateX(1px);
  border-top-color: black !important;
}

.react-tel-input{
  border-top:transparent !important;
  border-bottom-left-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  /* transform: translateY(-2px); */
  /* border-bottom-left-radius: 10px !important; */
}
.react-tel-input>.form-control{
  border-top:transparent !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  /* transform: translateY(-2px); */
  /* border-bottom-left-radius: 10px !important; */
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[type=text]:disabled {
  background: white !important;
}

#enquiry-form input[type=text]:disabled {
  background: #FFFFFF !important;
}

.checkbox__container>input:checked~.svg__container{
  transform: translateY(0);
}



.react-datepicker{
  background: white !important;
  display: flex !important;
  justify-content: center !important;
  gap: 50px;
  padding: 50px;
  /* padding-top: 150px !important; */
  border: none !important;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1) !important;

  left:0 !important;
  border-radius: unset !important;
  /* transform: translateY(32px) translateX(-330px); */
  transform-origin: left !important;
}

.react-datepicker * {
  outline: none !important;
}

.extend__container>*>.react-datepicker{
  background: white !important;
  display: flex !important;
  justify-content: center !important;
  gap: 50px;
  padding: 50px;
  width:100% !important;
  border: none !important;
  box-shadow: unset !important;
  left:0 !important;
  border-radius: unset !important;
  /* transform: translateY(32px) translateX(-330px); */
  transform-origin: left !important;
  flex-direction: column;
  align-items: center;
}


.react-datepicker-popper{
  transform: translate(-50%,120px) !important;
  width:1000px !important;
  left: 50% !important;
  top:-50px !important;
}

.react-datepicker__day--selected{
  border-radius: 100% !important;
  background: #FF7900 !important;
  box-shadow: 0 0 0 5px #FF7900 !important;
}
.react-datepicker__day--keyboard-selected{
  background:unset !important;
  color: black !important;
}

.react-datepicker__day:hover{
  border-radius: 100% !important;
  background-color: #00000011;
  box-shadow: 0 0 0 5px #00000011;
}

.react-datepicker__triangle{
  display: none !important;
}
.react-datepicker__header{
  background-color: white !important;
  border: none !important;
}
.react-datepicker__time-container{
  border: none !important;
}
.react-datepicker__day-names{
  display: flex;
  gap:20px;
  justify-content: center;
  border: none !important;
  font-size: smaller;

}
.react-datepicker__month-container{
  width:50%;
  border-right: 2px solid whitesmoke;
  padding-right: 50px;
}

/* FOR MOBILE */

.extend__container.mobile>*>.react-datepicker>.react-datepicker__month-container{
  border-right: unset !important;
  width: 270px !important;
  padding-right: 0 !important;
}

.extend__container.mobile>*>.react-datepicker>.react-datepicker__month-container>.react-datepicker__month{
  margin: 0 !important;
  margin-top: 0.4rem !important;
}

.extend__container.mobile>*>.react-datepicker>.react-datepicker__month-container>.react-datepicker__month>.react-datepicker__week{
  height: 25px !important;
  gap: 10px !important;
}

.extend__container.mobile>*>.react-datepicker>.react-datepicker__month-container>.react-datepicker__month>.react-datepicker__week>div{
  display:grid !important;
  place-items: center !important;
  line-height: unset !important;
  min-height: 18px !important;
}

.extend__container.mobile>*>.react-datepicker>.react-datepicker__navigation--previous{
  left:50px !important;
}
.extend__container.mobile>*>.react-datepicker>.react-datepicker__navigation--next{
  right:105px !important;
}

/* END FOR MOBILE */

.extend__container>*>.react-datepicker>.react-datepicker__month-container{
  border-right: unset !important;
  width: 331px !important;
  padding-right: 0 !important;
}

.extend__container>*>.react-datepicker>.react-datepicker__month-container>.react-datepicker__month{
  margin: 0 !important;
  margin-top: 0.4rem !important;
}
.extend__container>*>.react-datepicker>.react-datepicker__month-container>.react-datepicker__month>.react-datepicker__week{
  height: 25px !important;
}

.extend__container>*>.react-datepicker>.react-datepicker__month-container>.react-datepicker__month>.react-datepicker__week>div{
  display:grid !important;
  place-items: center !important;
  line-height: unset !important;
  min-height:25px !important;
}

.extend__container>*>.react-datepicker>.react-datepicker__navigation--previous{
  left:100px !important;
}
.extend__container>*>.react-datepicker>.react-datepicker__navigation--next{
  right:105px !important;
}

.react-datepicker__day--disabled{
  color: #CCCCCC !important;
  pointer-events: none;
}

.react-datepicker__week{
  display: flex;
  justify-content: center;
  gap:20px !important;
}
.react-datepicker__month{
  display: flex;
  gap:20px;
  flex-direction: column;
}
.react-datepicker__current-month{
  background-color: white;
  margin-bottom: 10px;
}

.react-datepicker__navigation{
  top:60px !important;

}
.react-datepicker__navigation--next{
  right:480px !important;
}



.react-datepicker__navigation--previous{
  left:170px !important;
}
.react-datepicker__navigation-icon::before{
  border-color:rgb(83, 83, 83) !important;
}

.react-datepicker__time-container {
  width: 15% !important;
}

.react-datepicker-time__input{
  height:75px;
  /* background:orange; */
  display: flex;
  font-size: 25px !important;
  /* gap:50px; */
}











.react-datepicker__input-time-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: flex-start;
}

.react-datepicker-time__input{
  margin-left: 0 !important;
}


input[type=time] {
  border: none;
  color: #2a2c2d;
  font-size: 14px;
  font-family: helvetica;
  width: 300px !important;
  display: flex !important;
  align-items: flex-start;
  justify-content: flex-start;
}

/* Wrapper around the hour, minute, second, and am/pm fields as well as 
the up and down buttons and the 'X' button */
input[type=time]::-webkit-datetime-edit-fields-wrapper {
  display: flex;
}

/* The space between the fields - between hour and minute, the minute and 
second, second and am/pm */
input[type=time]::-webkit-datetime-edit-text {
  padding: 19px 4px;
}

input[type=time]::-webkit-datetime-edit-hour-field {
  border-radius: 15%;
  padding: 19px 13px;
  
  width:35px;
  text-align: center;
  
  height:100%;
  transform: scaleY(.9);
  border: 1px solid rgb(194, 194, 194);
  margin-right: 10px;
}

/* Minute */
input[type=time]::-webkit-datetime-edit-minute-field {
  border-radius: 15%;
  padding: 19px 13px;
  width:35px;
  text-align: center;
  
  height:100%;
  transform: scaleY(.9) translateX(10px);
  border: 1px solid rgb(194, 194, 194);
}

/* AM/PM */
input[type=time]::-webkit-datetime-edit-ampm-field {
  background-color: #7155d3;
  border-radius: 15%;
  color: #fff;
  padding: 19px 13px;
  
  width:35px;
  text-align: center;
  
  height:100%;
  overflow: visible !important;
  transform: scaleY(.9);
  border: 1px solid rgb(194, 194, 194);
}

/* 'X' button for resetting/clearing time */
input[type=time]::-webkit-clear-button {
  display: none;
}

/* Up/Down arrows for incrementing/decrementing the value */
input[type=time]::-webkit-inner-spin-button {
  display: none;
}

/* Data Tables */
.jVeafb:not(:last-of-type),.jxflYm{
  border-bottom-width: 0 !important;
  border-top-width: 0 !important;
}
.jfrgbA,.jzGaAN{
  padding:0 !important;
}

.sc-dmRaPn, .gelpCx{
  overflow:visible !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: none;
  width: 20px;
  height: 20px;
  border-width: thin;
}

/* Google maps */
.gm-ui-hover-effect{
  display: none !important;
}

#invoice_datatable input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  border: 1px solid #e1e1e1;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

#invoice_datatable input[type="checkbox"]::before {
  content: "";
  width: 10px;
  height: 10px;
  clip-path: polygon(0% 45%, 0 60%, 40% 100%, 100% 20%, 100% 0%, 40% 80%);
  background-color: #ff7900;
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  /* Windows High Contrast Mode */
}

#invoice_datatable input[type="checkbox"]:checked::before {
  transform: scale(1);
}

#invoice_datatable input[type="checkbox"]:focus {
  outline: none;
}

#invoice_datatable input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

/* DataTable */
.ldPgTk {
  border-bottom: unset !important
}
.jYPiPR,.cLRkKo{
  padding-left:0 !important;
}

/* select drop-down */
*>select::-ms-expand {
  display: none !important;
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
}
*>select {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
}

.react-datepicker-popper {
  max-width: 100%;
}

.react-datepicker {
  flex-direction: column;
  padding: 10px;
}

.react-datepicker__month-container {
  width: 100%;
  border: none;
  padding-right: 0;
}

@media only screen and (min-width: 992px) {
  .react-datepicker-popper {
    max-width: auto;
  }
  
  .react-datepicker {
    flex-direction: row;
    padding: 50px;
  }
  
  .react-datepicker__month-container {
    width: 50%;
    border-right: 2px solid whitesmoke;
    padding-right: 50px;
  }
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid unset;
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
} */

select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid unset !important;
  -webkit-text-fill-color: black;
  box-shadow: 0 0 0px 1000px white inset;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.fadein_main {
  opacity: 0;
}

.fadein {
  opacity: 0;
}

.floatup {
  transform: translateY(50px);
  opacity: 0;
}

/* .googleMarker {
  transform: translate(-50%,-100%);
  animation: animMarker .35s forwards;
} */

/* @keyframes animMarker {
  0% {
    transform:  translate(-50%,-700%);
  }
  35% {
    transform:  translate(-50%,-100%);
  }
  60% {
    transform:  translate(-50%,-125%);
  }
  100% {
    transform:  translate(-50%,-100%);
  }
} */

.Toastify__toast-container {
  z-index: 100000 !important;
}

.mapMarkerBase {
  scale: 0;
}

/* .selectedPodBase {
  scale: 0;
  opacity: 0;
} */

*>.ytp-endscreen-content{
  display: none !important;
}

.vehicle-slider>*>.slick-dots{
  display:hidden !important;
}

.react-calendar__tile .react-calendar__tile--now {
  background-color: transparent;
}

.react-calendar__tile.react-calendar__century-view__decades__decade {
  padding: 0 !important;
  margin: auto !important;
  width: 100%;
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  position: relative;
  align-items: end;
}

.react-calendar__tile.react-calendar__decade-view__years__year {
  padding: 0 !important;
  margin: auto !important;
  width: 100%;
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  position: relative;
  align-items: end;
}

.react-calendar__tile.react-calendar__year-view__months__month {
  padding: 0 !important;
  margin: auto !important;
  width: 100%;
  padding-top: 16.5% !important;
  padding-bottom: 0% !important;
  position: relative;
  align-items: end;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  padding: 0 !important;
  margin: auto !important;
  width: 100%;
  padding-top: 100% !important;
  position: relative;
  align-items: end;
}

.react-calendar__tile > abbr {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -25%);
  z-index: 1;
}

.react-calendar__century-view__decades__decade.react-calendar__tile--hasActive {
  background-image: linear-gradient(white 10%, #FF7800 11%, #FF7800 90%, white 91%);
  width: 80%;
  height: 80%;
  z-index: 0;
  color: #FF7800;
}

.react-calendar__decade-view__years__year.react-calendar__tile--hasActive {
  background-image: linear-gradient(white 10%, #FF7800 11%, #FF7800 90%, white 91%);
  width: 80%;
  height: 80%;
  z-index: 0;
  color: #FF7800;
}

.react-calendar__year-view__months__month.react-calendar__tile--hasActive {
  background-image: linear-gradient(white 10%, #FF7800 11%, #FF7800 90%, white 91%);
  width: 80%;
  height: 80%;
  z-index: 0;
  color: #FF7800;
}

.react-calendar__month-view__days__day.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd::after {
  content: '.';
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FF7800 !important;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 80%;
  height: 80%;
  z-index: 0;
  color: #FF7800;
}

.react-calendar__tile--hasActive {
  color: #fff !important;
}

.react-calendar__month-view__days__day.react-calendar__tile--hasActive:not(.react-calendar__tile--rangeStart):not(.react-calendar__tile--rangeEnd)::after {
  content: '.';
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FF7800 !important;
  border-radius: 20px;
  width: 80%;
  height: 80%;
  z-index: 0;
  color: #FF7800;
}

.react-calendar__month-view__days__day.react-calendar__tile--rangeStart, .react-calendar__tile--rangeEnd {
  color: #fff !important;
}

.react-calendar__month-view__days__day.react-calendar__tile--rangeStart::after {
  content: '.';
  position: absolute;
  top: 10%;
  left: 0;
  background-color: #FF7800 !important;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  width: 105%;
  height: 80%;
  z-index: 0;
  color: #FF7800;
}

.react-calendar__month-view__days__day.react-calendar__tile--rangeEnd::after {
  content: '.';
  position: absolute;
  top: 10%;
  right: 0;
  background-color: #FF7800 !important;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 105%;
  height: 80%;
  z-index: 0;
  color: #FF7800;
}

.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
}

.react-calendar__month-view__days__day--weekend, .react-calendar__month-view__days__day {
  color: #282828;
  font-size: 16px;
  letter-spacing: 0.2;
  line-height: 1.438;
  display: flex !important;
  justify-content: center !important;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile:hover,
.react-calendar__tile--hover {
  background-color: transparent !important;
  background-image: linear-gradient(white 10%, #FF7800 11%, #FF7800 90%, white 91%);
  color: #fff !important;
}

.react-calendar__tile--active.react-calendar__tile--range:not(.react-calendar__tile--rangeStart):not(.react-calendar__tile--rangeEnd) {
  background-image: linear-gradient(white 10%, #FF7800 11%, #FF7800 90%, white 91%);
  color: #fff !important;
}
.react-calendar__tile--active:enabled:focus {
  background-color: transparent;
}

.react-calendar__tile:disabled {
  background-color: transparent !important;
  color: #d4d4d4 !important;
}
.react-calendar__month-view__weekdays {
  display: grid !important;
  grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  margin-bottom: 10px;
  justify-items: center;
}
.react-calendar__month-view__weekdays__weekday > abbr {
  color: #282828 !important;
  text-decoration: none !important;
  text-transform: capitalize;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
}

.react-calendar__navigation {
  display: flex;
}

.react-calendar__navigation__label__labelText {
  font-weight: 700;
  margin: 0 auto;
}

.react-calendar__navigation button:disabled {
  opacity: 0;
}

.react-calendar__navigation button, .react-calendar__navigation button:hover
{
  background-color: transparent !important;
}

.react-calendar__month-view__days__day--neighboringMonth:not(.react-calendar__month-view__days__day--neighboringMonth:disabled):not(.react-calendar__tile--active.react-calendar__tile--range) {
  color: #000 !important;
}

.myAccountBlock a {
  color: #FF7800;
}

/* HEADER */
.main-header {
  padding: 40px 0;
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}

.main-header .header-container {
  max-width: 1770px;
  margin: 0 auto;
  width: 100%;
}

.main-header .header-container .header-nav-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.main-header .toggle-wrap {
  display: none;
}

#homelogo {
  padding-right: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.menu.overlay {
  z-index: 1;
}

.homelogo-img-wrap {
  width: 120px;
  /* width: 160px; */
  height: 44px;
  margin: 0 auto;
}

.homelogo-img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

@media (min-width: 768px) {
  .homelogo-img {
    /* MD Breakpoint */
    margin-right: auto;
    margin-left: 0px;
  }
}
.toggle-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.toggle-wrap .toggle-menu .nav__navicon {
  cursor: pointer;
}

.toggle-wrap .toggle-menu .nav__navicon.active {
  z-index: 3;
}

.toggle-wrap .toggle-menu .nav__navicon.active .nav__burger:first-child {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggle-wrap .toggle-menu .nav__navicon.active .nav__burger:nth-child(2) {
  opacity: 0;
}

.toggle-wrap .toggle-menu .nav__navicon.active .nav__burger:last-child {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.toggle-wrap .toggle-menu .nav__navicon.active .nav__burger:first-child {
  -webkit-transform-origin: 3px 1px;
  transform-origin: 3px 1px;
}

.toggle-wrap .toggle-menu .nav__navicon.active .nav__burger:last-child {
  -webkit-transform-origin: 6px 1px;
  transform-origin: 6px 1px;
}

.toggle-wrap .toggle-menu .nav__navicon .nav__burger {
  display: block;
  width: 30px;
  height: 2px;
  background-color: #ff7900;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.toggle-wrap .toggle-menu .nav__navicon .nav__burger:not(:last-child) {
  margin-bottom: 5px;
}

.toggle-menu button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 12px;
  border: solid 1px;
}

.menu {
  width: 100%;
  display: block;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-top: 10px;
}

@media (min-width: 1024px) {
  .menu {
    /* LG Breakpoint */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    width: auto;
  }
}
.menu .primary-button,
a.menu-sign-in.primary-button {
  background-color: #289c8d;
  border: 1px solid #289c8d;
  color: #ffffff;
  font-size: 15px;
  line-height: 1.22;
  text-align: center;
  font-family: "Roboto", sans-serif;
  min-width: 133px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 51px;
  padding: 8px 24px;
  /* border: none; */
  min-height: 44px;
  text-align: center;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  color: #ffffff;
  cursor: pointer;
  font-weight: 700;
}

.menu .primary-button:hover,
a.menu-sign-in.primary-button:hover {
  background-color: #267372;
}

.menu .primary-button:first-child,
a.menu-sign-in.primary-button {
  margin-right: 25px;
}

.menu .secondary-button,
a.menu-sign-in.secondary-button {
    background-color: #ffffff;
    border: 2px solid #289c8d;
    color: #289c8d;
    font-size: 15px;
    line-height: 1.22;
    text-align: center;
    font-family: "Roboto", sans-serif;
    min-width: 133px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 51px;
    padding: 8px 24px;
    /* border: none; */
    min-height: 44px;
    text-align: center;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    cursor: pointer;
    font-weight: 700;
}

.menu .secondary-button:hover,
a.menu-sign-in.secondary-button:hover {
    background-color: #ffffff;
    color: #1e7471;
    border: 2px solid #1e7471;
}

.menu .secondary-button:focus,
a.menu-sign-in.secondary-button:focus {
    background-color: #cde9e1;
    color: #1e7471;
    border: 2px solid #1e7471;
    outline: none;
}

.menu .secondary-button:first-child,
a.menu-sign-in.secondary-button {
    margin-right: 25px;
}

.menu-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 50px;
}

.menu-items a {
  /* padding-right: 31.6px;
  padding-left: 24px; */
  display: block;
  /* margin-top: 16px;  */
  text-decoration: none;
  color: #ff7900;
  font-size: 14px;
  text-align: left;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.22;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  position: relative;
}

.menu-items a:after {
  position: absolute;
  left: 0px;
  background-color: #ff7900;
  content: "";
  display: block;
  height: 2px;
  margin-top: 0.2em;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  width: 0;
}

.menu-items a:hover:after,
.menu-items .is-active::after {
  width: 100%;
}

@media (min-width: 1024px) {
  .menu-items {
    /* LG Breakpoint */
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .menu-items a {
    display: inline-block;
    margin-top: 0;
  }
}
@media (min-width: 1024px) {
  .mobile-topnav {
    display: none;
  }
}
.mobile-topnav button {
  background-color: #289c8d;
  color: #ffffff;
  font-family: "Nunito", sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 51px;
  padding: 8px 24px;
  border: none;
  max-width: 96px;
  min-width: auto;
  min-height: 30px;
  font-size: 10px;
  line-height: 1.2;
  text-align: center;
  margin-right: 20px;
}

/* LG Breakpoint */
/* @media (min-width: 1024px) {
  #homelogo {
    margin-right: 1rem;
  }
} */
@media (max-width: 1770px) {
  .main-header {
    padding: 40px;
  }
}
@media (max-width: 1669px) {
  .main-header {
    padding: 40px 20px;
  }
  #homelogo {
    padding-right: 50px;
  }
  .menu-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 30px;
  }
}
@media (max-width: 1440px) {
  #homelogo {
    padding-right: 25px;
  }
  .menu-items {
    gap: 30px;
  }
  .menu .primary-button {
    min-width: 120px;
  }
}
@media (max-width: 1155px) {
  .menu-items .is-active::after {
    width: 0;
  }
  .main-header {
    padding: 20px;
  }
  .main-header .toggle-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  #topnav {
    display: none;
  }
  /* .homelogo-img-wrap {
    width: 120px;
  } */
  .main-header .header-container .header-nav-wrap {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .mobile-topnav {
    display: block;
  }
  .menu {
    position: absolute;
    top: 100%;
    background: #ffffff;
    width: 100%;
    left: 0;
    opacity: 0;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    display: block;
    pointer-events: none;
  }
  .menu.overlay {
    opacity: 1;
    pointer-events: auto;
  }
  .menu-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px 25px 40px;
  }
  .menu-items a:hover:after {
    width: 0;
  }
}
/* @media (min-width: 1024px) {
  .toggle-menu { */
/* LG Breakpoint */
/* display: none;
  }
} */
@media (max-width: 1024px) {
  .main-header {
    padding: 20px;
  }
  .menu-items a {
    padding: 0;
  }
}

.nav-title-container {
    position: relative;
    display: inline-block;
}

.nav-item-subtitle {
    display: none;
    position: absolute;
    background-color: #fff;
    border: none;
    padding: 16px;
    z-index: 1;
    top: 100%;
    left: 10px;
}

.nav-item-subtitle a {
    text-wrap: nowrap;
}

.nav-title-container:hover .nav-item-subtitle {
    display: block;
}

/* FOOTER */

.main-footer {
  background-color: #686564;
  padding: 50px;
  font-family: "Nunito", sans-serif;
}

.main-footer .footer-container {
  max-width: 1620px;
  margin: 0 auto;
}

.main-footer .footer-container .footer-nav-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.main-footer .footer-container .social-icons.mobile {
  display: none;
}

.main-footer .footer-container .footer-nav-wrap .footer-logo {
  width: 160px;
  height: 34px;
}

.main-footer .footer-container .footer-nav-wrap .footer-logo a {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.main-footer .footer-container .footer-nav-wrap .footer-logo img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.main-footer .footer-container .footer-nav-links {
  margin-left: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 50px;
  align-items: start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  margin-top: 10px;
}

.main-footer .footer-container .footer-nav-links a {
  color: #ffffff;
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  position: relative;
}

.main-footer .footer-container .footer-nav-links a:after {
  position: absolute;
  left: 0px;
  background-color: #fff;
  content: "";
  display: block;
  height: 0.1em;
  margin-top: 0.2em;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  width: 0;
}

.main-footer .footer-container .footer-nav-links a:hover:after {
  width: 100%;
}

.main-footer .footer-container hr {
  margin: 50px 0;
}

.main-footer .footer-container .sub-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.main-footer .footer-container .sub-footer p {
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
  margin: 0;
}

.main-footer .footer-container .sub-footer .social-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}

.main-footer .footer-container .sub-footer .social-icons a {
  text-decoration: none;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.main-footer .footer-container .sub-footer .social-icons a:hover,
.main-footer .footer-container .footer-nav-wrap .footer-logo a:hover {
  opacity: 0.7;
}

@media (max-width: 1400px) {
  .main-footer .footer-container .footer-nav-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 30px;
  }
  .main-footer .footer-container .footer-nav-links {
    margin-left: 0px;
  }
}
@media (max-width: 1110px) {
  .main-footer .footer-container .footer-nav-links {
    gap: 20px;
  }
}
@media (max-width: 991px) {
  /* .main-footer .footer-container .footer-nav-links {
    flex-wrap: wrap;
    justify-content: center;
  } */
  .main-footer .footer-container .footer-nav-wrap {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
  }
  .main-footer .footer-container .footer-nav-links {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
  }
  .main-footer .footer-container .sub-footer .social-icons {
    display: none;
  }
  .main-footer .footer-container .social-icons.mobile {
    margin-top: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 25px;
  }
}
@media (max-width: 767px) {
  .main-footer {
    padding: 50px 35px;
  }
  .main-footer .footer-container .footer-nav-links a {
    font-size: 14px;
  }
}

.item-title-container {
    position: relative;
    display: inline-block;
}

.item-subtitle {
    display: none;
    position: absolute;
    background-color: #686564;
    border: #fff solid 1px;
    padding: 16px;
    z-index: 1;
    top: 100%;
    left: 10px;
}

.item-title-container:hover .item-subtitle {
    display: block;
}

.item-subtitle a {
    text-wrap: nowrap;
}

/* PUBLIC LOGIN MODAL */

.public-modal {
  display: none;
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(219, 14%, 22%, 0.4);
  z-index: 2;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

.public-modal.show {
  display: flex;
}

.public-modal-div {
  box-sizing: border-box;
  padding: 50px 70px;
  max-width: 490px;
  width: 100%;
  background-color: rgb(242, 242, 242);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.public-modal-input-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.public-modal-input-div .input-div {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: white;
  padding: 0 16px;
  border: solid 1px;
  border-color: rgb(212, 212, 216);
}

.public-modal-input-div .input-div input {
  outline: none;
}

.public-modal-input-div .input-top-padding {
  height: 12px;
  box-sizing: border-box;
  margin: 0;
}

.public-modal-input-div .input-div input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px white inset !important;
}

.public-modal .error-message {
  display: none;
  color: rgb(239, 68, 68);
  font-size: 12px;
}

.login-bottom-div {
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 25px;
  font-size: 12px;
}

.input-div {
  position: relative;
}

.input-div.invalid {
  border-color: #ff0000;
}

.input-div input::placeholder {
  color: #b9b9b9;
}

.input-div .pw-icon {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.input-div .pw-icon svg {
  width: 100%;
  height: 100%;
}

.input-div .pw-icon .pw-show {
  color: var(--orange);
  fill: var(--orange);
}

.input-div .pw-icon svg.hidden {
  display: none;
}

.input-placeholder {
  font-size: 16px;
  color: #8e8e8e;
  position: absolute;
  left: inherit;
  top: 50%;
  transform: translateY(-50%);
  transition: all ease 0.2s;
}

.input-div input:focus-within + .input-placeholder,
.input-div input:not(:placeholder-shown) + .input-placeholder {
  top: 10px;
  transform: translateY(0);
  font-size: 9px;
}

.btn-sign-in.disabled {
  background-color: rgba(61, 68, 81, 0.2);
  pointer-events: none;
}

.btn-sign-in .loader {
  display: none;
}

.btn-sign-in.loading .loader {
  display: inline;
  border: 2px solid #fff;
  height: 16px;
  width: 16px;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 767px) {
  .login-bottom-div {
    margin-top: 50px;
    font-size: 14px;
  }
  .public-modal-div {
    padding: 30px;
    margin: 10px;
  }
}
.input-div label {
  min-height: 18px;
}

.modal-logo-div {
  width: 100%;
  max-width: 200px;
  height: auto;
  margin: 0 auto 60px;
}

.helpful-videos-slider .slick-next {
  right: -60px;
  top: 40%;
  z-index: 10;
  height: 100%;
  width: 40px;
}
.helpful-videos-slider .slick-prev {
  left: -60px;
  top: 40%;
  z-index: 10;
  height: 100%;
  width: 40px;
}
.helpful-videos-slider .slick-next:before {
  content: '❯';
  color: #ff7800;
}
.helpful-videos-slider .slick-prev:before {
  content: '❮';
  color: #ff7800;
}

.dynamic-content-container-public a {
  color: #FF7800;
}

.dynamic-content-container-public ul > li {
  list-style-type: disc;
  margin-left: 40px;
  margin-bottom: 16px;
}

.dynamic-content-container-public ol > li {
  list-style-type: decimal;
  margin-left: 40px;
  margin-bottom: 16px;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
   color: transparent;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
   color: transparent;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
   color: transparent;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
   color: transparent;
}
input::placeholder {
   color: transparent;
}
textarea::-webkit-input-placeholder {
  /* WebKit browsers */
   color: transparent;
}
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
   color: transparent;
}
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
   color: transparent;
}
textarea:-ms-input-placeholder {
  /* Internet Explorer 10+ */
   color: transparent;
}
textarea::placeholder {
   color: transparent;
}

.registration__calendar .react-calendar__navigation__label {
  margin-bottom: 15px !important;
}

button:hover {
  outline: none !important;
}